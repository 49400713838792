<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">PLANES DE FORMACIÓN REGISTRADO EN EL SISTEMA
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-pencil-alt fa-3x"></i> <br>
                            <span class="lg-numero">{{listaPlanesFormacion.length}}</span>
                            <br>
                            <span class="text-muted">Planes de formación</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de planes de formación</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col md="6" class="my-2">
                            <b-button block :to="{name: 'Recursos humanos'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-REC-PLA','c')==1" md="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarPlan = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo plan
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Planes de formación registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaPlanesFormacion" :fields="campoPlanes" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(docentes)="param">
                                    <span v-if="param.item.docentes==1">Internos</span>
                                    <span v-else-if="param.item.docentes==2">Externos</span>
                                </template>
                                <template  v-if="checkPermissions('009-REC-REC-PLA','u')==1"  #cell(opciones)="param">
                                    <b-button @click="abrirModalActualizarPlan(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('009-REC-REC-PLA','d')==1"   @click="eliminarPlanFormacion(param)" size="sm" variant="danger" class="mr-1 mb-1" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalRegistrarPlan">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo plan de formación </span>
            </h6>
            <CButtonClose @click="modalRegistrarPlan = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPlan)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="tema de capacitación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tema de capacitación:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.temaCapacitacion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el tema de capacitación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="cantidad de personas que han sido capacitadas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cantidad de personas que han sido capacitadas:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.cantidadPersonasCapacitadas" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la cantidad de personas"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="programa de capacitación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Programa de capacitación:" class="mb-2">
                                <b-form-file ref="file" v-model="datosPlanFormacion.programaCapacitacion" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPlanFormacion.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPlanFormacion.idProceso" :options="comboProceso" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Docentes:" class="my-2">
                            <b-form-radio-group small plain v-model="datosPlanFormacion.docentes" :options="radioDocentes"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha prevista" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha prevista:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.fechaPrevista" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha real" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha real:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.fechaReal" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha realización" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha realización:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.fechaRealizacion" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="metodo de eficacia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Metodo de eficacia:" class="mb-2">
                                <b-form-input type="text" v-model="datosPlanFormacion.metodoEficacia" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el método de eficacia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="¿Quién lo realiza?" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="¿Quién lo realiza?" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.quienRealiza" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese quién lo realiza"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="evaluación eficacia de la acción" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Evaluación Eficacia de la Acción:" class="mb-2">
                                <b-form-input v-model="datosPlanFormacion.evaluacionEficaciaAccion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la evaluación eficacia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contenido"  v-slot="validationContext">
                            <b-form-group label="Contenido:" class="mb-2">
                                <b-form-textarea v-model="datosPlanFormacion.contenido" rows="3" max-rows="6" size="md" placeholder="Ingrese el contenido" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosPlanFormacion.observaciones" rows="3" max-rows="6" size="md" placeholder="Ingrese las observaciones" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarPlan = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarPlan">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar plan de formación</span></h6>
            <CButtonClose @click="modalActualizarPlan = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarPlan)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="tema de capacitación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tema de capacitación:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.temaCapacitacion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el tema de capacitación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="cantidad de personas que han sido capacitadas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cantidad de personas que han sido capacitadas:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.cantidadPersonasCapacitadas" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la cantidad de personas"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="programa de capacitación" v-slot="validationContext">
                            <b-form-group label="Programa de capacitación:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarPlanFormacion.urlProgramaCapacitacion != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarPlanFormacion.urlProgramaCapacitacion)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar" v-model="datosActualizarPlanFormacion.programaCapacitacion" v-on:change="handleFileUploadActualizar" :state="getValidationState(validationContext)" :placeholder="datosActualizarPlanFormacion.nombreProgramaCapacitacion ? datosActualizarPlanFormacion.nombreProgramaCapacitacion : 'Seleccionar documento'" :drop-placeholder="datosActualizarPlanFormacion.nombreProgramaCapacitacion ? datosActualizarPlanFormacion.nombreProgramaCapacitacion : 'Seleccionar documento'" browse-text="Subir"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarPlanFormacion.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarPlanFormacion.idProceso" :options="comboProceso" @search:blur="blurActualizarProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Docentes:" class="my-2">
                            <b-form-radio-group small plain v-model="datosActualizarPlanFormacion.docentes" :options="radioDocentes"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha prevista" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha prevista:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.fechaPrevista" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha real" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha real:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.fechaReal" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha realización" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha realización:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.fechaRealizacion" type="date" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="metodo de eficacia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Metodo de eficacia:" class="mb-2">
                                <b-form-input type="text" v-model="datosActualizarPlanFormacion.metodoEficacia" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el método de eficacia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="¿Quién lo realiza?" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="¿Quién lo realiza?" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.quienRealiza" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese quién lo realiza"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="evaluación eficacia de la acción" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Evaluación Eficacia de la Acción:" class="mb-2">
                                <b-form-input v-model="datosActualizarPlanFormacion.evaluacionEficaciaAccion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la evaluación eficacia "></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contenido" v-slot="validationContext">
                            <b-form-group label="Contenido:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarPlanFormacion.contenido" rows="3" max-rows="6" size="md" placeholder="Ingrese el contenido" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarPlanFormacion.observaciones" rows="3" max-rows="6" size="md" placeholder="Ingrese las observaciones" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarPlan = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalRegistrarPlan: false,
            modalActualizarPlan: false,
            totalRows: 0,
            currentPage: 1,
            porPagina: 5,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboProceso: [],
            radioDocentes: [{
                    value: 1,
                    text: 'Internos'
                },

                {
                    value: 2,
                    text: 'Externos'
                }
            ],
            campoPlanes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "temaCapacitacion",
                    label: "Tema de capacitación",
                    class: "text-center",
                },
                {
                    key: "contenido",
                    label: "Contenido",
                    class: "text-center",
                },
                {
                    key: "docentes",
                    label: "Docentes",
                    class: "text-center",
                },
                {
                    key: "fechaReal",
                    label: "Fecha real",
                    class: "text-center",
                },
                {
                    key: "evaluacionEficaciaAccion",
                    label: "Evaluación eficacia de la acción",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaPlanesFormacion: [],

            datosPlanFormacion: {
                temaCapacitacion: '',
                cantidadPersonasCapacitadas: '',
                programaCapacitacion: null,
                idProceso: '',
                docentes: 1,
                fechaPrevista: moment().format('YYYY-MM-DD'),
                fechaReal: moment().format('YYYY-MM-DD'),
                fechaRealizacion: moment().format('YYYY-MM-DD'),
                metodoEficacia: '',
                quienRealiza: '',
                evaluacionEficaciaAccion: '',
                contenido: '',
                observaciones: '',
                urlProgramaCapacitacion: '',
                nombreProgramaCapacitacion: ''
            },
            datosActualizarPlanFormacion: {
                idPlaFormacion: '',
                temaCapacitacion: '',
                cantidadPersonasCapacitadas: '',
                programaCapacitacion: null,
                idProceso: '',
                docentes: 1,
                fechaPrevista: '',
                fechaReal: '',
                fechaRealizacion: '',
                metodoEficacia: '',
                quienRealiza: '',
                evaluacionEficaciaAccion: '',
                contenido: '',
                observaciones: '',
                urlProgramaCapacitacion: '',
                nombreProgramaCapacitacion: ''
            },
            datosSession: {
                idCliente: ''
            },
            disabled: false
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosPlanFormacion.programaCapacitacion = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }

                this.datosActualizarPlanFormacion.programaCapacitacion = this.$refs.file.files[0];
            }
        },
        listarPlanesFormacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-planes-formacion", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaPlanesFormacion = response.data;
                    me.totalRows = me.listaPlanesFormacion.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProceso = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarPlan() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosPlanFormacion.programaCapacitacion);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'recursos-humanos/planes-formacion');

            formData.append("temaCapacitacion", me.datosPlanFormacion.temaCapacitacion);
            formData.append("cantidadPersonasCapacitadas", me.datosPlanFormacion.cantidadPersonasCapacitadas);
            formData.append("idProceso", me.datosPlanFormacion.idProceso);
            formData.append("docentes", me.datosPlanFormacion.docentes);
            formData.append("fechaPrevista", me.datosPlanFormacion.fechaPrevista);
            formData.append("fechaReal", me.datosPlanFormacion.fechaReal);
            formData.append("fechaRealizacion", me.datosPlanFormacion.fechaRealizacion);
            formData.append("metodoEficacia", me.datosPlanFormacion.metodoEficacia);
            formData.append("quienRealiza", me.datosPlanFormacion.quienRealiza);
            formData.append("evaluacionEficaciaAccion", me.datosPlanFormacion.evaluacionEficaciaAccion);
            formData.append("contenido", me.datosPlanFormacion.contenido);
            formData.append("observaciones", me.datosPlanFormacion.observaciones);
            formData.append("urlProgramaCapacitacion", me.datosPlanFormacion.urlProgramaCapacitacion);
            formData.append("nombreProgramaCapacitacion", me.datosPlanFormacion.nombreProgramaCapacitacion);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-plan-formacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarPlanesFormacion();
                    me.modalRegistrarPlan = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarPlan() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosActualizarPlanFormacion.programaCapacitacion);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'recursos-humanos/planes-formacion');

            formData.append("idPlanFormacion", me.datosActualizarPlanFormacion.idPlanFormacion);
            formData.append("temaCapacitacion", me.datosActualizarPlanFormacion.temaCapacitacion);
            formData.append("cantidadPersonasCapacitadas", me.datosActualizarPlanFormacion.cantidadPersonasCapacitadas);
            formData.append("idProceso", me.datosActualizarPlanFormacion.idProceso);
            formData.append("docentes", me.datosActualizarPlanFormacion.docentes);
            formData.append("fechaPrevista", me.datosActualizarPlanFormacion.fechaPrevista);
            formData.append("fechaReal", me.datosActualizarPlanFormacion.fechaReal);
            formData.append("fechaRealizacion", me.datosActualizarPlanFormacion.fechaRealizacion);
            formData.append("metodoEficacia", me.datosActualizarPlanFormacion.metodoEficacia);
            formData.append("quienRealiza", me.datosActualizarPlanFormacion.quienRealiza);
            formData.append("evaluacionEficaciaAccion", me.datosActualizarPlanFormacion.evaluacionEficaciaAccion);
            formData.append("contenido", me.datosActualizarPlanFormacion.contenido);
            formData.append("observaciones", me.datosActualizarPlanFormacion.observaciones);
            formData.append("urlProgramaCapacitacion", me.datosActualizarPlanFormacion.urlProgramaCapacitacion);
            formData.append("nombreProgramaCapacitacion", me.datosActualizarPlanFormacion.nombreProgramaCapacitacion);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-plan-formacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarPlanesFormacion();
                    me.modalActualizarPlan = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurActualizarProceso() {
            this.computedActualizarForm.refs.proceso.validate();
        },
        abrirModalActualizarPlan(param) {
            let me = this;
            me.datosActualizarPlanFormacion.idPlanFormacion = param.item.idPlanFormacion;
            me.datosActualizarPlanFormacion.temaCapacitacion = param.item.temaCapacitacion;
            me.datosActualizarPlanFormacion.cantidadPersonasCapacitadas = param.item.cantidadPersonasCapacitadas;
            me.datosActualizarPlanFormacion.programaCapacitacion = null;
            me.datosActualizarPlanFormacion.urlProgramaCapacitacion = param.item.urlProgramaCapacitacion;
            me.datosActualizarPlanFormacion.nombreProgramaCapacitacion = param.item.nombreProgramaCapacitacion;
            me.datosActualizarPlanFormacion.idProceso = param.item.idProceso;
            me.datosActualizarPlanFormacion.docentes = param.item.docentes;
            me.datosActualizarPlanFormacion.fechaPrevista = param.item.fechaPrevista;
            me.datosActualizarPlanFormacion.fechaReal = param.item.fechaReal;
            me.datosActualizarPlanFormacion.fechaRealizacion = param.item.fechaRealizacion;
            me.datosActualizarPlanFormacion.metodoEficacia = param.item.metodoEficacia;
            me.datosActualizarPlanFormacion.quienRealiza = param.item.quienRealiza;
            me.datosActualizarPlanFormacion.evaluacionEficaciaAccion = param.item.evaluacionEficaciaAccion;
            me.datosActualizarPlanFormacion.contenido = param.item.contenido;
            me.datosActualizarPlanFormacion.observaciones = param.item.observaciones;

            me.modalActualizarPlan = true;

        },
        resetModalRegistrarPlan() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosPlanFormacion.temaCapacitacion = '';
            this.datosPlanFormacion.cantidadPersonasCapacitadas = '';
            this.datosPlanFormacion.programaCapacitacion = null;
            this.datosPlanFormacion.idProceso = null;
            this.datosPlanFormacion.docentes = 1;
            this.datosPlanFormacion.fechaPrevista = moment().format('YYYY-MM-DD');
            this.datosPlanFormacion.fechaReal = moment().format('YYYY-MM-DD');
            this.datosPlanFormacion.fechaRealizacion = moment().format('YYYY-MM-DD');
            this.datosPlanFormacion.metodoEficacia = '';
            this.datosPlanFormacion.quienRealiza = '';
            this.datosPlanFormacion.evaluacionEficaciaAccion = '';
            this.datosPlanFormacion.contenido = '';
            this.datosPlanFormacion.observaciones = '';

        },
        resetModalActualizarPlan() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        eliminarPlanFormacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el plan de formación?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-plan-formacion", {
                            idPlanFormacion: param.item.idPlanFormacion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPlanesFormacion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarPlan: function (val) {
            if (val == false) {
                this.resetModalRegistrarPlan();
            }
        },
        modalActualizarPlan: function (val) {
            if (val == false) {
                this.resetModalActualizarPlan();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPlanesFormacion();
            this.listarProcesos();
        }
    }

}
</script>
